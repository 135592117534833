<template>
  <v-container class="mt-12 mb-12 doc-page text-body-1">
    <section>
      <h1 class="mb-4 text-h4 font-weight-bold">What Is The Project?</h1>
      <div>
        Dental radiography is an essential tool for the diagnosis of dental
        diseases and is commonly used by Dentists all around the world with an
        estimated 46 million dental x-rays taken a year in the UK alone.
        However, the analysis of radiographs is often time-consuming and
        error-prone due to a high variety of dental structures,
        positioning/orientation errors, and area biases when ordering a scan. An
        automatic solution for flagging abnormalities and detecting diseases in
        dental radiographs is needed to improve detection accuracy and to
        minimise the patients physical and emotional harm due to misdiagnosis.
        <br /><br />

        This project aims to use AI to bridge the gap in radiographic diagnosis,
        by building a software prototype enabling computers to recognise normal
        and abnormal anatomical structures and differentiate between subtle
        abnormalities present in radiographs. Using state of the art and
        specialised object detection techniques, we aim create a radiograph
        viewing and automatic dental charting application with AI assisted
        disease detection and localisation.
        <br /><br />

        The project has been sponsored by the EPSRC Impact Acceleration Account
        (IAA) and the Research England Higher Education Innovation Fund (HEIF).
        <br /><br />

        <div class="text-center">
          <img
            class="ma-2"
            src="@/assets/ukri_logo_1.png"
            alt=""
            width="300px"
          />
          <img
            class="ma-2"
            src="@/assets/ukri_logo_2.png"
            alt=""
            width="180px"
          />
        </div>
      </div>
    </section>

    <v-divider class="my-8"></v-divider>

    <section>
      <h1 class="mb-4 text-h4">What Diseases We Are Looking For?</h1>
      <h3 class="mt-4 mb-2 text-h6">Bone Loss (Periodontal Disease)</h3>

      Periodontal disease is caused by gram-negative anaerobic bacteria, Which
      initially cause inflammation in the gums and progresses onto destroying
      the jawbone and the connective tissue. After the bacteria start to destroy
      the bone, there is no way to regain that bone naturally after it is lost,
      which makes periodontitis a very important disease to detect in the early
      stages.
      <br />

      There are 4 stages to periodontal disease:
      <br /><br />

      <b>Stage 1:</b> gingivitis: inflammation of the gums and is only diagnosed
      through visual inspection. It can be reversed via conventional dental
      hygiene. <br />

      <b>Stage 2:</b> initial: The bone starts to be destroyed and the bone line
      recedes. Requires dentist intervention to stop and cannot be reversed.
      <br />

      <b>Stage 3:</b> Moderate: The bone line has receded more creating more
      space in the gums for bacteria to enter. The bacteria can now enter the
      bloodstream and affect the immune system. Will need a dentist to perform a
      deep clean to get rid of and is irreversible. <br />

      <b>Stage 4:</b> Advanced: Is classed as bone loss of more than 50%. Very
      high chance of losing your teeth in the affected area, severe chance of
      abscess and infection, and serious loss of jawbone. This stage also has a
      high chance to spread to other areas of the head and can only be stopped
      through surgery or laser treatment. This stage cannot be reversed and will
      most likely result in reconstructive surgery.
      <img
        class="my-2"
        src="@/assets/about-project-a.png"
        alt=""
        width="100%"
      />

      <h3 class="mt-4 mb-2 text-h6">Dental Caries (Tooth Decay)</h3>
      Dental caries or tooth decay is caused by gram-positive coccus bacteria
      and is the most common dental disease. Most of the time it can be spotted
      through visual inspection, however, if the affected area is in between
      teeth, it becomes almost impossible to detect via visual inspection alone.
      Coincidentally, The most affected area for dental caries is between the
      teeth, due to widespread poor flossing habits. Cavities caused by caries
      can be easily treated by cleaning the affected area and applying a
      filling. However, If the decay is not detected by the dentist, it can
      cause tooth loss, infections, abscesses, and even lead to periodontal
      disease.
      <br /><br />

      <b>Stage 1:</b> Enamel De-Mineralisation: The enamel becomes weaker and
      more translucent. <br />

      <b>Stage 2:</b> Enamel Decay (Early): Decay starts eroding the hard enamel
      layer, this stage takes a long time to progress onto the next stage.
      <br />

      <b>Stage 3:</b> Dentin Decay: Decay has made it to the dentin layer of the
      tooth. This stage progresses much faster than stage 2. <br />

      <b>Stage 4:</b> Pulp Decay (Advanced): Decay has reached the pulp layer of
      the tooth which can cause infections. <br />

      <b>Stage 5:</b> Abscess (periapical radiolucency): Decay has caused an
      infection that has travelled down to the bottom of the root, causing an
      abscess or cist. <br /><br />

      <div class="text-center">
        <img
          class="my-2"
          src="@/assets/about-project-b.png"
          alt=""
          width="80%"
        />
      </div>
      <div class="text-center">
        <img
          class="my-2"
          src="@/assets/about-project-c.png"
          alt=""
          width="80%"
        />
      </div>
      <div class="text-center">
        <img
          class="my-2"
          src="@/assets/about-project-d.png"
          alt=""
          width="80%"
        />
      </div>
      <div class="text-center">
        <img
          class="my-2"
          src="@/assets/about-project-e.png"
          alt=""
          width="80%"
        />
      </div>

      <h3 class="mt-4 mb-2 text-h6">Calculus Plaque (Tartar)</h3>
      <div>
        Calculus plaque occurs when plaque has become calcified after gingival
        crevicular fluid from plaque mixes with the minerals in saliva when the
        teeth have not been properly cleaned when brushing. Calculus can be
        supragingival or subgingival, above the gums or below the gums
        respectively. When viewing calculus on a radiograph, due to the similar
        densities of the plaque and teeth, it appears as the same shade as the
        teeth, making it hard to spot by shading alone. However, calculus does
        not follow the normal profile of the teeth so you can easily spot it by
        comparing the shape of the affected teeth with a healthy tooth.
      </div>
      <div class="text-center">
        <img
          class="my-2"
          src="@/assets/about-project-f.jpg"
          alt=""
          width="80%"
        />
      </div>
    </section>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss">
// @import "~vuetify/src/styles/main.sass";

// h1 {
//   @extend .text-h2 !optional;
// }
</style>