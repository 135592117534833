<template>
  <v-card
    v-intersect="intersectHandler"
    :flat="!isIntersecting"
    class="pa-4 d-flex transparent transition shadow-hover"
    :class="{ shadow: isIntersecting }"
    :ripple="false"
    :to="to"
  >
    <img
      class="rounded-circle img-profile"
      :src="profile"
      :alt="`${name}'s profile`"
    />

    <div class="pl-4">
      <h3 class="text-h5">{{ name }}</h3>

      <div
        class="text-subtitle-2 font-weight-bold overflow-hidden"
        :class="{ 'line-limit-2': !fullcontent }"
      >
        {{ position }}
      </div>

      <div
        class="text-body-2 overflow-hidden"
        :class="{ 'line-limit-4': !fullcontent }"
      >
        {{ description }}
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    profile: {
      type: String,
      default: require("@/assets/person.png"),
    },
    name: {
      type: String,
      default: " ",
    },
    position: {
      type: String,
      default: " ",
    },
    description: {
      type: String,
      default: " ",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    fullcontent: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      isIntersecting: false,
    };
  },

  methods: {
    onIntersect(entries, observer) {
      this.isIntersecting = entries[0].isIntersecting;
      // console.log(entries[0].isIntersecting);
    },
  },

  computed: {
    intersectHandler() {
      if (this.autofocus)
        return {
          handler: this.onIntersect,
          options: {
            threshold: [0, 0.3, 0.7, 1],

            // When element is within 40% - 52% of screen height (from top).
            // More: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
            rootMargin: "-40% 0px -48% 0px",
          },
        };

      return {};
    },
  },
};
</script>

<style scoped lang="scss">
.img-profile {
  width: 100px;
  height: 100px;

  @media #{map-get($display-breakpoints, 'xs-only' )} {
    width: 80px;
    height: 80px;
  }
}
</style>