<template>
  <v-container class="mt-12 mb-12 doc-page">
    <!-- Title Section -->
    <h1 class="mb-4 text-h4 font-weight-bold">Meet the Team</h1>
    <div class="text-subtitle-2">
      Our wonderful team is made up of many different students and
      professionals, all with a wide range of expertise and a passion for AI and
      Dental Radiography!
    </div>

    <!-- Team Members Section -->
    <div v-for="(team, i) in teams" :key="i">
      <v-divider class="my-8"></v-divider>

      <div class="d-flex flex-md-row flex-column">
        <!-- Role Header -->
        <div class="mb-4 flex-shrink-0 role-header" style="width: 260px">
          <h2 class="text-h5">{{ team.role }}</h2>
          <div class="grey--text">{{ team.description }}</div>
        </div>

        <!-- Members List -->
        <div class="flex-grow-1">
          <MemberCard
            v-for="(member, i) in team.members"
            :key="i"
            :class="{ 'mt-4': i != 0 }"
            :name="member.name"
            :position="member.position"
            :description="member.description"
            :profile="member.profile"
            autofocus
            :to="getBioPath(member)"
          />
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import MemberCard from "@/components/MemberCard";
import { loadTeams } from "@/services/teams";

export default {
  components: {
    MemberCard,
  },

  data() {
    return {
      teams: [],
      dialog: false,
      focusMember: () => ({}),
    };
  },

  async beforeRouteEnter(to, from, next) {
    let teams = await loadTeams();
    next((vm) => (vm.teams = teams));
  },

  mounted() {
    if (!this.teams.length) loadTeams().then((teams) => (this.teams = teams));
  },

  methods: {
    getBioPath(member) {
      let key = member.name.replaceAll(" ", "-");
      return `/about/team/${key}`;
    },
  },
};
</script>

<style scoped lang="scss">
.role-header {
  @media #{map-get($display-breakpoints, 'md-and-up' )} {
    position: sticky;
    top: 16px;
    z-index: 1;
    align-self: start;
  }
}
</style>