<template>
  <v-container class="mt-12 mb-12 doc-page">
    <section>
      <h1 class="mb-4 text-h4 font-weight-bold">How Much Data Do We Have?</h1>
      <div>
        We conducted rounds of data collection, in order to acquire the
        necessary data to train the AI models. We have collected anonymised
        radiographs from a range of trusted dentists, however these radiographs
        are unfortunately unlabelled. We have collected a total of 7,145
        bitewing radiographs, 666 panoramic radiographs, and 148 CBCT scans.
        <br /><br />

        In order to label these radiographs, we have launched a Zooniverse
        project in order to use members of the public, with an interest in the
        project, to apply bounding boxes and labels to the data. We have seen
        good success in this project so far and have completed the first round
        of labelling with over 2,700 radiographs labelled.
        <br /><br />

        If you are interested in participating the second round of labelling,
        please visit our Zooniverse Project at:
        <a
          href="https://www.zooniverse.org/projects/huhui/dental-disease-detection"
        >
          www.zooniverse.org/projects/huhui/dental-disease-detection
        </a>

        <img src="@/assets/zooniverse_data.png" alt="" width="100%" />
        <br /><br />

        We are also developing our own labelling and annotation web-based tool,
        called DR CAPS. This web application gives us the freedom to implement
        additional features to assist in the labelling of diseases, thus
        improving the quality of the labels. Some of these features include
        active learning, ai assisted labelling, contrast/brightness adjustment,
        and many more features.
        <br /><br />

        In order to maximise the quality of the labels, this web app is only
        available to dentists, dental students, and dental professionals. So, if
        you have dental training and are interested in joining the DR CAPS
        expert data collection, then please email us at
        <a href="mailto:dental.disease.detection@gmail.com"
          >dental.disease.detection@gmail.com</a
        >
      </div>
    </section>

    <v-divider class="my-8"></v-divider>

    <section>
      <h1 class="mb-4 text-h4">What Is A Radiograph?</h1>
      <div>
        A radiograph is an image that has been captured using x-ray, gamma, or
        similar radiation, to pass through an object onto a sensor or plate,
        revealing the substructures of that object. Radiographs are commonly
        used for medical examination and diagnosis of diseases and other medical
        disorders.

        <div class="mt-2 text-center">
          <img src="@/assets/dental-xrays.png" alt="" width="80%" />
          <p class="text-caption">
            Image from
            <a
              href="https://depositphotos.com/117206200/stock-photo-female-patient-face-with-x.html"
              >depositphotos.com</a
            >
          </p>
        </div>
      </div>

      <h2 class="mt-4 mb-2 text-h5">Dental X-rays</h2>
      <div>
        In the field of Dentistry, taking x-ray radiographs is common practice
        for the diagnosis of diseases that are not easily detected using the
        naked eye. In the UK adults need at least 1 radiograph taken a year for
        a general check-up, with an estimated 46 million radiographs taken each year.

        <h3 class="mt-4 mb-2 text-h6">Intraoral</h3>
        Intraoral x-rays are a range of radiographic imaging techniques where
        the image is taken by emitting the radiation source from outside the
        patient’s mouth towards a sensor that is placed inside the mouth. These
        radiographs are used to detect diseases and monitor the health of the
        teeth themselves and the surrounding jawbone. They only show a few teeth
        on one side of the mouth only, and they are usually taken when the
        dentist knows where a disease or issue might be.
        <div class="my-2 text-center">
          <img src="@/assets/intraoral.png" alt="" width="300px" />
          <p class="text-caption">
            Image from
            <a href="https://dentimax.com/x-ray-image-gallery/">dentimax.com</a>
          </p>
        </div>
        Intra oral radiographs are the most common dental radiographs, as they
        are more detailed, show a smaller more specific area of the mouth, emit
        less radiation, and are cheaper then extraoral images and 3D scans.

        <h3 class="mt-4 mb-2 text-h6">Extraoral</h3>
        Extraoral radiographs are images that are taken with both the x-ray
        source and sensor placed outside the mouth with the patient placed
        between them. These types of radiographs take an image of a large
        section of the mouth or the entire head of the patient, and usually take
        poorer quality images than intraoral images.
        <br /><br />

        These images are usually taken when the dentist does not know where the
        issue is, they want to examine the entire mouth or head for issues, or
        if they want to see how dental structures are interacting with other
        parts of the head.
        <div class="my-2 text-center">
          <img src="@/assets/extraoral.png" alt="" width="300px" />
          <p class="text-caption">
            Image from
            <a
              href="https://www.orbitimaging.com/imaging-services/digital-x-ray/cephalometric-projections-2/"
              >www.orbitimaging.com</a
            >
          </p>
        </div>
      </div>

      <h2 class="mt-4 mb-2 text-h5">CBCT Scans</h2>
      <div>
        Cone Based Computed Tomography is the most commonly used machine for
        taking a 3D scans for dental purposes. They work by spinning a x-ray
        source and sensor around the patients head, capturing multiple 2D
        radiographs in ‘slices’ moving to the next area of the mouth for each
        slice. When all the slices are captured, a computer takes the slices and
        generates a 3D model from them, which can be manipulated and explored.
        CBCT scans are incredibly high detention and capture a lot of
        information, which a trained clinician can use to examine the detailed
        3D model or scroll through the slices of 2D images.

        <div class="my-2 text-center">
          <img src="@/assets/cbct.png" alt="" width="80%" />
          <div class="text-caption">CBCT Slice</div>
          <p class="text-caption">
            Image from
            <a
              href="https://www.researchgate.net/publication/327062161_A_borderline_dental_trauma_with_12_y_of_evolution_justifying_CBCT_as_diagnostic_method"
              >www.researchgate.net</a
            >
          </p>
        </div>

        <div class="my-2 text-center">
          <img src="@/assets/cbc_3d.png" alt="" width="300px" />
          <div class="text-caption">CBCT Rendered 3D Model</div>
          <p class="text-caption">
            Image from
            <a
              href="https://www.researchgate.net/figure/A-virtual-model-of-a-CBCT-for-advanced-backward-planning-of-prosthetics-and-dental_fig3_353792228"
              >www.researchgate.net</a
            >
          </p>
        </div>

        CBCT scans are rarely used for the diagnosis of diseases, as they are
        expensive and emit a significantly larger amount of radiation. They are
        more commonly used for mapping out dental structures to plan tricky
        surgeries and reconstruction work, or to get a better understanding of
        the severity of already diagnosed diseases.

        <h3 class="mt-4 mb-2 text-h6">Panoramic</h3>
        Panoramic radiographs are a type of extraoral x-ray that take an image
        of every tooth and the surrounding structures of a patient, from the
        front of the face. This radiograph is used to examine the entire mouth
        for diseases and issues, instead of a single area or side of the head.
        However, panoramic radiographs are poorer quality due amount of objects
        captured by the image, and are often not suitable for the diagnosis of
        early stage diseases. They are often used to get a general idea of the
        health of the mouth and to identify areas of interest, so the dentist
        can order more detailed images for that specific area.
        <br /><br />

        While a panoramic radiograph is an extraoral x-ray, not a scan, most
        panoramic radiographs are taken using a CBCT machine, with few being
        taken from a dedicated panoramic machine.

        <div class="my-2 text-center">
          <img src="@/assets/panoramic.png" alt="" width="80%" />
          <p class="text-caption">
            Image from
            <a
              href="https://www.researchgate.net/publication/294258178_A_Clinical_Comparison_of_Extraoral_Panoramic_and_Intraoral_Radiographic_Modalities_for_Detecting_Proximal_Caries_and_Opening_Posterior_Interproximal_Contacts"
              >www.researchgate.net</a
            >
          </p>
        </div>
      </div>
    </section>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>