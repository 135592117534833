<template>
  <v-container>
    <v-card class="pa-4 mt-4 mx-auto" style="max-width: 300px">
      <div class="text-h6">PAGE NOT FOUND!</div>
      <div class="text-caption text-weight-bold">
        PATH: {{ $route.fullPath }}
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>