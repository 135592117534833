import moment from 'moment'
import axios from './axios'
import { IMAGE_URL, TEAM_API_URL } from './config'

// TEAM API

export const loadTeams = async () => {
  // return [
  //   {
  //     role: "Principal Investigator",
  //     description: "Something describe this",
  //     members: [
  //       {
  //         name: "Mark Lastname",
  //         position: "Researcher",
  //         description:
  //           "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto quae distinctio et repudiandae assumenda inventore eveniet natus delectus corrupti similique molestiae perspiciatis qui, corporis, eum dolorem magnam sequi eligendi ut!",
  //       },
  //     ],
  //   },
  //   {
  //     role: "Co-Investigators",
  //     description: "Something describe this",
  //     members: [
  //       {
  //         name: "Mark Lastname",
  //         position: "Researcher",
  //         description:
  //           "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto quae distinctio et repudiandae assumenda inventore eveniet natus delectus corrupti similique molestiae perspiciatis qui, corporis, eum dolorem magnam sequi eligendi ut!",
  //       },
  //       {
  //         name: "Mark Lastname",
  //         position: "Researcher",
  //         description:
  //           "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto quae distinctio et repudiandae assumenda inventore eveniet natus delectus corrupti similique molestiae perspiciatis qui, corporis, eum dolorem magnam sequi eligendi ut!",
  //       },
  //     ],
  //   },
  //   {
  //     role: "Researcher",
  //     description: "Something describe this",
  //     members: [
  //       {
  //         name: "Mark Lastname",
  //         position: "Researcher",
  //         description:
  //           "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto quae distinctio et repudiandae assumenda inventore eveniet natus delectus corrupti similique molestiae perspiciatis qui, corporis, eum dolorem magnam sequi eligendi ut!",
  //       },
  //       {
  //         name: "Mark Lastname",
  //         position: "Researcher",
  //         description:
  //           "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto quae distinctio et repudiandae assumenda inventore eveniet natus delectus corrupti similique molestiae perspiciatis qui, corporis, eum dolorem magnam sequi eligendi ut!",
  //       },
  //     ],
  //   },
  // ]

  let { data, status } = await axios.get(TEAM_API_URL)
  if (status != 200) return {}

  // Format Image url
  data.teams.forEach(team => {
    team.members.forEach(member => {
      member.profile = IMAGE_URL(member.profile)
    })
  })

  return data.teams
}


export const postTeam = async ({ role, description }) => {
  const result = await axios.post(TEAM_API_URL, {
    role,
    description
  })
  return result
}

export const putTeam = async ({ _id, role, description }) => {
  const result = await axios.put(`${TEAM_API_URL}/${_id}`, {
    role,
    description
  })
  return result
}

export const deleteTeam = async (_id) => {
  const result = await axios.delete(`${TEAM_API_URL}/${_id}`)
  return result
}


// TEAM MEMBER API

export const postMember = async ({ team_id, name, position, description, profile }) => {
  if (!team_id) throw Error('team_id is null')
  let formData = new FormData()
  let data = { team_id, name, position, description, profile }
  for (let key in data) {
    if (!data[key]) continue
    if (Array.isArray(data[key])) {
      data[key].forEach((d) => formData.append(key, d))
    } else {
      formData.append(key, data[key])
    }
  }
  const result = await axios.post(`${TEAM_API_URL}/member/${team_id}`, formData)
  return result
}

export const putMember = async ({ _id, name, position, description, profile }) => {
  if (!_id) throw Error('_id is null')
  let formData = new FormData()
  let data = { _id, name, position, description, profile }
  for (let key in data) {
    if (!data[key]) continue
    if (Array.isArray(data[key])) {
      data[key].forEach((d) => formData.append(key, d))
    } else {
      formData.append(key, data[key])
    }
  }
  const result = await axios.put(`${TEAM_API_URL}/member/${_id}`, formData)
  return result
}

export const deleteMember = async (_id) => {
  const result = await axios.delete(`${TEAM_API_URL}/member/${_id}`)
  return result
}