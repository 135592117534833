import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    setLoading: null,
    isLoading: false,
  },

  getters: {},

  mutations: {
    setLoadingFunction(state, func) {
      if (!state.setLoading)
        state.setLoading = func;
    },

    async setIsLoading(state, payload) {
      if (!state.setLoading) return;
      state.isLoading = payload;
      state.setLoading(payload);
    }
  },

  actions: {
    startLoading({ state, commit }) {
      commit('setIsLoading', true)
    },
    
    stopLoading({ commit, state }) {
      commit('setIsLoading', false)
    }
  },

  modules: {}
})
