<template>
  <v-container class="mt-12 mb-12 doc-page">
    <section>
      <h1 class="mb-4 text-h4">Why am I doing this?</h1>
      <div>
        We need you to help us add bounding boxes on dental radiographs, so we
        can use the bounding boxes to train an artificial intelligence algorithm
        to automatically detect diseases. A system that can accurately detect
        dental diseases could potentially reduce the rate of misdiagnosis and
        prevent unnecessary pain and suffering.
      </div>
    </section>

    <section>
      <h1 class="mt-8 mb-4 text-h4">How do I get started?</h1>
      <div>
        If this is your first time on this project or you don't have much
        experience identifying dental diseases from radiographs, then please
        start on the "Easy" workflow.
      </div>

      <div>
        If you do have prior experience identifying dental diseases, then please
        work on the "advanced" workflow.
      </div>

      <div>
        If you are struggling to identify diseases, and want to see some
        examples. Then please select the "Tutorial" workflow
      </div>
    </section>

    <section>
      <h1 class="mt-8 mb-4 text-h4">What if I have made a mistake?</h1>
      <div>
        If you have applied a bounding box in the wrong place. You can click on
        the box and drag it to move it to the correct place, or you can click on
        the X button on the top right of the bounding box to delete it.
      </div>

      <div>
        If the bounding box is too small/large. You can resize the bounding box
        by clicking on it, then click on the corners and drag to resize.
      </div>

      <div>
        If you have clicked "Done", but you have realised there is a mistake.
        You cannot go back and change it, so make sure bounding boxes are
        correct before clicking done.
      </div>
    </section>

    <section>
      <h1 class="mt-8 mb-4 text-h4">How can I get better at identifying dental diseases?</h1>
      <div>
        Make sure you watch the tutorial video by clicking on the tutorial tab
        on your specific workflow. If you need a reminder or more in-depth
        examples for identifying dental diseases, then click on the "field
        guide", read through it, and watch the "example tutorial". If you are
        still confused about any aspect of identifying the diseases, then
        contact us on the "Talk" section, or email us at
        dental.disease.detection@gmail.com.
      </div>
    </section>

    <section>
      <h1 class="mt-8 mb-4 text-h4">
        What if I have another question or issue not mentioned on this page?
      </h1>

      <div>
        If you need help or are not sure about something. Then please ask a
        question on the "Talk" section of the project, or email us at:
        dental.disease.detection@gmail.com
      </div>
    </section>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>