<template>
  <v-card
    class="px-2 py-4 transparent text-center shadow-hover flowup-hover"
    style="min-width: 240px"
    :to="to"
    :ripple="false"
  >
    <!-- <img src="@/assets/xray.jpg" alt="" width="240px" height="200px" /> -->

    <div class="">
      <v-icon size="48">{{ icon }}</v-icon>
    </div>

    <div class="mt-2">
      <h4 class="text-h5">{{ title }}</h4>
      <div>
        <slot>Content here</slot>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: { default: "mdi-account-group" },
    title: { type: String, default: "The Project" },
    to: { type: String, default: undefined },
  },
};
</script>

<style>
</style>