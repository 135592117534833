<template>
  <v-app-bar
    app
    flat
    dark
    style="background: #363636"
    :hide-on-scroll="autoHide"
    v-scroll="onScroll"
  >
    <!-- For Desktop -->
    <v-row no-gutters class="d-none d-md-flex align-center">
      <v-col cols="3" class="align-self-center">
        <router-link
          to="/"
          class="white--text text-h6 text-decoration-none text-no-wrap"
        >
          <img src="@/assets/project-logo.png" alt="" width="48px" />
          Dental Disease Detection
        </router-link>
      </v-col>

      <v-col cols="6" class="text-center">
        <v-btn
          x-large
          plain
          to="/"
          active-class="disable-active"
          :ripple="false"
        >
          Home
        </v-btn>

        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-large
              plain
              class="px-3"
              active-class="disable-active"
              :ripple="false"
              v-on="on"
              v-bind="attrs"
            >
              About
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list nav dense width="140px">
            <v-list-item-group>
              <v-list-item to="/about/project"> The Project </v-list-item>

              <v-list-item to="/about/team" exact> Our Team </v-list-item>

              <v-list-item to="/about/ourdata"> Our Data </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-btn
          x-large
          plain
          to="/news"
          class="px-3"
          active-class="disable-active"
          :ripple="false"
        >
          News
        </v-btn>

        <!-- <v-btn
          x-large
          plain
          to="/faq"
          class="px-3"
          active-class="disable-active"
          :ripple="false"
        >
          FAQ
        </v-btn> -->
      </v-col>

      <v-col cols="3" class="text-right">
        <!-- <v-text-field placeholder="Search" hide-details dense prepend-inner-icon="mdi-magnify"></v-text-field> -->
        <!-- <v-btn
          text
          to="/manage"
          active-class="disable-active"
          class="ml-2"
          :ripple="false"
        >
          Sign In
        </v-btn> -->
      </v-col>
    </v-row>

    <!-- For Mobile -->
    <v-row no-gutters class="d-md-none d-flex align-center">
      <router-link
        to="/"
        class="white--text text-h6 text-decoration-none text-no-wrap"
      >
        <img src="@/assets/project-logo.png" alt="" width="48px" />
        <span class="hidden-xs-only"> Dental Disease Detection </span>
      </router-link>

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon @click="$emit('clicked')"></v-app-bar-nav-icon>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      autoHide: false,
    };
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const yPos = window.pageYOffset || e.target.scrollTop || 0;
      this.autoHide = yPos > 64;
    },
  },
};
</script>

<style>
</style>