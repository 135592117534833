<template>
  <v-card class="transparent" style="position: relative">
    <!-- Cover Background Image (Right) -->
    <v-carousel
      class="cover-bg hidden-xs-only"
      height="430"
      hide-delimiter-background
      :cycle="cycle"
      interval="7000"
      v-model="currentIndex"
    >
      <v-carousel-item
        v-for="(item, i) in contents"
        :key="i"
        :src="item.coverImage"
        contain
      >
      </v-carousel-item>

      <template v-slot:prev>
        <v-icon large @click="previousIndex">mdi-chevron-left</v-icon>
      </template>

      <template v-slot:next>
        <v-icon large @click="nextIndex">mdi-chevron-right</v-icon>
      </template>
    </v-carousel>

    <!-- News Content (Left) -->
    <v-card flat class="pa-2 rounded-0 card-content" style="height: 430px">
      <div style="height: 180px">
        <img
          :src="content.thumbnail"
          alt=""
          width="100%"
          style="height: 180px"
        />
      </div>

      <div style="height: 234px; overflow: hidden; position: relative">
        <div class="mt-2 text-h6" style="line-height: 1.5rem">
          {{ content.title }}
        </div>

        <div class="text-caption grey--text">
          {{ content.timestamp }}
          <span v-if="content.tags && content.tags.length"> | </span>
          <span class="mr-1" v-for="(tag, i) in content.tags" :key="i">{{
            tag
          }}</span>
        </div>

        <div class="mt-1 text-subtitle-2">
          {{ content.textBody }}
        </div>

        <div class="pt-1 d-flex align-center card-footer">
          <!-- Previous / Pause / Next Button -->
          <v-btn class="hidden-sm-and-up" icon small @click="previousIndex">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>

          <v-btn icon small @click="cycle = !cycle">
            <v-icon v-if="cycle" small>mdi-pause</v-icon>
            <v-icon v-else small>mdi-play</v-icon>
          </v-btn>

          <v-btn class="hidden-sm-and-up" icon small @click="nextIndex">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <router-link class="link text-caption" :to="`/news/${content._id}`">
            Read more
          </router-link>
        </div>
      </div>
    </v-card>
  </v-card>
</template>

<script>
export default {
  props: {
    contents: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      currentIndex: 0,
      cycle: true,
    };
  },

  methods: {
    nextIndex() {
      if (this.currentIndex >= this.contents.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
      this.stopCycle();
    },

    previousIndex() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.contents.length - 1;
      } else {
        this.currentIndex--;
      }
      this.stopCycle();
    },

    stopCycle() {
      this.cycle = false;
    },
  },

  computed: {
    content() {
      return this.contents[this.currentIndex] || {};
    },
  },

  watch: {},
};
</script>

<style lang="scss" scoped>
.cover-bg {
  position: absolute;
  left: 240px;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto;
  /* pointer-events: none; */
}

.card-content {
  width: 240px;
  background: rgb(255, 255, 255) !important;

  @media #{map-get($display-breakpoints, 'xs-only' )} {
    width: auto;
  }
}

.card-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 20%,
    rgba(255, 255, 255, 1) 100%
  );
}

.img-bg {
  background-image: url("https://cdn.pixabay.com/photo/2022/05/16/18/17/sheep-7200918_960_720.jpg");
  background-position: center;
  background-size: cover;
}
</style>