<template>
  <v-footer class="d-block text-caption">
    <v-container class="d-flex justify-space-around footer-container">
      <div class="d-flex flex-column align-center">
        <!-- <b class="font-weight-bold">Some text / another logo</b> -->
        <img src="@/assets/uni-logo.png" alt="" width="200px" />
        <img class="mt-4" src="@/assets/ukri_logo_1.png" alt="" width="200px" />
        <!-- <img class="mt-4" src="@/assets/ukri_logo_2.png" alt="" width="120px" /> -->
      </div>

      <div class="d-flex flex-column">
        <b class="text-body-1 font-weight-bold">Links</b>
        <router-link to="/" class="link grey--text text--darken-1"
          >Home</router-link
        >
        <router-link to="/about" class="link grey--text text--darken-1"
          >The Project</router-link
        >
        <router-link to="/about/team" class="link grey--text text--darken-1"
          >Our Team</router-link
        >
        <router-link to="/news" class="link grey--text text--darken-1"
          >News</router-link
        >
        <!-- <router-link to="/faq" class="link grey--text text--darken-1"
          >FAQ</router-link
        > -->
      </div>

      <div class="d-flex flex-column">
        <b class="text-body-1 font-weight-bold">Resources</b>
        <a
          href="https://www.zooniverse.org/projects/huhui/dental-disease-detection"
          class="link grey--text text--darken-1"
          >Our Zooniverse Project</a
        >
        <a
          href="https://www.ukri.org/what-we-offer/browse-our-areas-of-investment-and-support/epsrc-impact-acceleration-accounts/"
          class="link grey--text text--darken-1"
          >EPSRC impact acceleration account</a
        >
        <a
          href="https://www.ukri.org/what-we-offer/browse-our-areas-of-investment-and-support/higher-education-innovation-fund/ Ï"
          class="link grey--text text--darken-1"
          >Research England Higher Education Innovation Fund (HEIF)</a
        >
      </div>

      <div class="d-flex flex-column">
        <b class="text-body-1 font-weight-bold">Contact us</b>
        <a
          href="mailto:dental.disease.detection@gmail.com"
          class="link grey--text text--darken-1"
          >dental.disease.detection@gmail.com</a
        >
      </div>
    </v-container>

    <div class="mt-2 text-body-1 text-center font-weight-black">
      All rights reserved
    </div>
  </v-footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
// @import "~vuetify/src/styles/styles.sass";

.footer-container {
  div {
    max-width: 200px;
  }

  @media #{map-get($display-breakpoints, 'lg-only' )} {
    div {
      max-width: 300px;
    }
  }

  // Only apply when screen is sm-and-down
  @media #{map-get($display-breakpoints, 'sm-and-down' )} {
    flex-direction: column;
    align-items: center;

    div {
      margin: 12px 0;
      text-align: center;
      max-width: unset;
    }
  }
}
</style>