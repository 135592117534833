<template>
  <v-app>
    <ProgressBar />
    <NavBar @clicked="drawer = !drawer" />
    <Content />
    <Footer />
    <Drawer v-model="drawer" />
  </v-app>
</template>

<script>
import NavBar from "@/components/core/NavBar.vue";
import Content from "@/components/core/Content.vue";
import Footer from "@/components/core/Footer.vue";
import Drawer from "@/components/core/Drawer.vue";
import ProgressBar from "@/components/core/ProgressBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
    Content,
    Footer,
    Drawer,
    ProgressBar,
  },
  data() {
    return {
      drawer: false,
    };
  },
};
</script>

<style lang="scss">
@import "@/scss/global.scss";
</style>
