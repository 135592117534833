import axios from './axios'
import { AUTHEN_API_URL, BASE_API_URL, USER_API_URL } from './config'

function saveUserData(user) {
    sessionStorage.setItem('user', JSON.stringify(user));
}

function clearUserData() {
    sessionStorage.removeItem('user')
}

function getUserData() {
    let user = sessionStorage.getItem('user')
    if (!user) return null
    return JSON.parse(user)
}


export const signin = async (query) => {
    try {
        let { status, data } = await axios.get(`${AUTHEN_API_URL}/google`, { params: query })
        if (status != 200) return null

        saveUserData(data)
        return data
    } catch ({ response }) {
        return null
    }
}

export const signout = async (clearDataOnly = false) => {
    try {
        clearUserData()
        if (clearDataOnly) return;

        let { status, data } = await axios.get(`${AUTHEN_API_URL}/logout`)
        if (status != 200) return null

        return data
    } catch ({ response }) {
        return null
    }
}

export const getProfile = async (noCache = false) => {
    try {
        if (!noCache) {
            let user = getUserData()
            return user
        }

        let { status, data } = await axios.get(`${AUTHEN_API_URL}/profile`, { params: { timestamp: new Date().getTime() } })
        if (status != 200) return null

        saveUserData(data)
        return data
    } catch ({ response }) {
        return null
    }
}

export const isLoggedIn = async () => {
    let profile = await getProfile()
    return profile ? true : false
}

export const getUsers = async () => {
    try {
        let { status, data } = await axios.get(`${USER_API_URL}`)
        return data
    } catch (error) {
        return null
    }
}

export const postUser = async (email) => {
    try {
        let { status, data } = await axios.post(`${USER_API_URL}`, { email })
        return data
    } catch (error) {
        return null
    }
}

export const deleteUser = async (_id) => {
    try {
        let { status, data } = await axios.delete(`${USER_API_URL}/${_id}`)
        return data
    } catch (error) {
        return null
    }
}