import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// import store from '@/store'
import { isLoggedIn } from '@/services/users'


//  Pre-Loaded Pages 
import Home from '@/pages/Home'
import NotFound from '@/pages/NotFound'
import News from '@/pages/News'
import AboutProject from '@/pages/About/Project'
import AboutTeam from '@/pages/About/Team'
import MemberBio from '@/pages/About/Team/MemberBio'
import OurData from '@/pages/About/OurData'
import FAQ from '@/pages/FAQ'


// Lazy Loaded
const Manage = () => import('@/pages/Manage')
const ManageNews = () => import('@/pages/Manage/News')
const ManageTeam = () => import('@/pages/Manage/Team')
const ManageUser = () => import('@/pages/Manage/User')
const NewsEditor = () => import('@/pages/Manage/News/Editor')
const SignIn = () => import('@/pages/SignIn')
const NewsReader = () => import('@/pages/News/NewsReader')


// Admin Guard
const adminGuard = async (to, from, next) => {
  if (await isLoggedIn()) {
    next()
  } else {
    next({ name: 'SignIn' })
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/news',
    name: 'News',
    component: News
  }, {
    path: '/news/:_id',
    name: 'NewsReader',
    component: NewsReader
  }, {
    path: '/about',
    redirect: '/about/project'
  }, {
    path: '/about/project',
    name: 'AboutProject',
    component: AboutProject
  }, {
    path: '/about/team',
    name: 'AboutTeam',
    component: AboutTeam
  }, {
    path: '/about/team/:member',
    name: 'MemberBio',
    component: MemberBio
  }, {
    path: '/about/ourdata',
    name: 'OurData',
    component: OurData
  }, {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },

  // ADMIN ZONE
  {
    path: '/manage',
    name: 'Manage',
    component: Manage,
    props: true,
    beforeEnter: adminGuard,
    children: [
      {
        path: 'news',
        component: ManageNews,
      },
      {
        path: 'team',
        component: ManageTeam
      },
      {
        path: 'user',
        component: ManageUser
      },
    ]
  }, {
    path: '/manage/news/post',
    name: 'NewsPost',
    component: NewsEditor,
    beforeEnter: adminGuard,
  }, {
    path: '/manage/news/:_id',
    name: 'NewsEditor',
    component: NewsEditor,
    beforeEnter: adminGuard,
  }, {
    path: '/signin',
    name: 'SignIn',
    component: SignIn
  },

  // FALLBACK
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition
    else return { x: 0, y: 0 }
  }
})

// router.beforeEach((to, from, next) => {
//   // If this isn't an initial page load.
//   if (to.name) {
//     // Start the route progress bar.
//     // store.dispatch('startLoading')
//   }
//   next()
// })

// router.afterEach((to, from) => {
//   // Complete the animation of the route progress bar.
//   // store.dispatch('stopLoading')
// })

export default router
