<template>
  <div>
    <section>
      <v-img
        src="@/assets/heading-bg.jpg"
        alt=""
        width="100%"
        min-height="450px"
        height="65vh"
        max-height="720px"
        gradient="to top, rgba(0,0,0,.7), rgba(0,0,0,.1)"
        class="align-end"
      >
        <div class="px-4 pb-16 mb-4 text-center white--text">
          <h1 class="text-h3 font-weight-bold flowup">
            We are using <u>AI</u> to Detect Dental Diseases
          </h1>

          <!-- <div class="mt-4 text-h6 font-weight-bold">
            Help us find dental diseases in these radiographs, to develop
            automated disease detection!
          </div> -->
        </div>
      </v-img>
    </section>

    <v-container class="py-12">
      <div
        class="d-flex justify-space-around flex-column flex-md-row align-center"
      >
        <linkCard
          class="mb-2"
          to="/about"
          icon="mdi-projector-screen-outline"
          title="The Project"
        >
          Learn about the project
        </linkCard>

        <linkCard
          class="mb-2"
          to="/about/team"
          icon="mdi-account-group-outline"
          title="Our Team"
        >
          Meet the teams
        </linkCard>

        <linkCard
          class="mb-2"
          to="/about/ourdata"
          icon="mdi-database-outline"
          title="Our Data"
        >
          Explore our data
        </linkCard>
      </div>
    </v-container>

    <section class="py-12 inner-shadow about-container">
      <v-container class="doc-page white--text">
        <div class="text-center">
          <!-- <h2 class="text-h4 font-weight-bold">
            About dental disease detection
          </h2> -->
          <div class="mt-4 text-body-1">
            We are researching and developing new techniques in the field of AI
            and Machine Learning, to produce an AI assisted radiograph viewing
            and charting diagnostic tool, for dental clinicians and assistants.
          </div>
        </div>

        <v-divider class="my-8"></v-divider>

        <div class="d-flex pa-2 flex-column flex-md-row align-center">
          <img src="@/assets/anno-xray.png" alt="" style="max-width: 320px" />

          <div class="pa-4 text-center flex-grow-1">
            <div class="mx-auto" style="max-width: 300px">
              <h3 class="mt-4 text-h6">
                If you are interested in the project and would like to help us,
                please visit our Zooniverse project!
              </h3>
              <v-btn
                outlined
                class="mt-4 white--text"
                href="https://www.zooniverse.org/projects/huhui/dental-disease-detection "
                >Help us</v-btn
              >
            </div>
          </div>
        </div>
      </v-container>
    </section>

    <v-container v-if="contents.length" class="mt-8 mb-12">
      <h2 class="text-h4">News</h2>
      <newsCard class="mt-4" :contents="contents" />
    </v-container>
  </div>
</template>

<script>
import { loadContents } from "@/services/contents";
import linkCard from "./linkCard";
import newsCard from "./newsCard";

export default {
  components: {
    linkCard,
    newsCard,
  },
  data() {
    return {
      contents: [
      ],
    };
  },

  mounted() {
    loadContents({ maxItems: 5 }).then(({ contents }) => {
      this.contents = contents;
    });
  },
};
</script>

<style lang="scss" scoped>
.about-container {
  background: #686763;
  background: radial-gradient(
    ellipse at center bottom,
    rgba(68, 68, 75, 0.5) 0%,
    rgba(104, 103, 99, 1) 100%
  );

  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  img,
  a:not(:hover) {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.33);
  }
}
</style>