<template>
  <v-navigation-drawer app right temporary v-model="drawer" class="text-center">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold">
          Dental Disease Detection
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item-group color="primary">
        <v-list-item v-for="(link, i) in links" :key="i" :to="link.to">
          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <!-- <template v-slot:append>
      <div class="pa-2">
        <v-btn
          text
          block
          to="/manage"
          active-class="disable-active"
          :ripple="false"
        >
          Sign In
        </v-btn>
      </div>
    </template> -->
  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["value"],

  data() {
    return {
      drawer: false,
      links: [
        {
          title: "Home",
          to: "/",
        },
        {
          title: "About Project",
          to: "/about/project",
        },
        {
          title: "About Team",
          to: "/about/team",
        },
        {
          title: "Our Data",
          to: "/about/ourdata",
        },
        {
          title: "News",
          to: "/news",
        },
        // {
        //   title: "FAQ",
        //   to: "/faq",
        // },
      ],
    };
  },

  mounted() {
    this.drawer = this.value;
  },

  watch: {
    value() {
      this.drawer = this.value;
    },

    drawer() {
      this.$emit("input", this.drawer);
    },

    "$vuetify.breakpoint.smAndDown": function(isSmAndDown) {
      if (this.drawer) this.drawer = isSmAndDown;
    }
  },
};
</script>

<style>
</style>