<template>
  <div style="z-index: 99; position: fixed; top: 0; width: 100%">
    <v-fade-transition>
      <v-progress-linear
        v-if="value"
        v-model="value"
        background-opacity="0"
      ></v-progress-linear>
    </v-fade-transition>
  </div>
</template>

<script>
import store from "@/store";

export default {
  data() {
    return {
      value: 0,
      loading: false,
    };
  },

  mounted() {
    // Register 'setLoading' function
    store.commit('setLoadingFunction', this.setLoading);

    // this.increment();
  },

  methods: {
    async increment() {
      // Start loading animation
      this.loading = true;
      let pauseValue = 70 + Math.random() * 10; // 70 - 80

      // Fast progres
      while (this.loading) {
        this.value += 1;
        if (this.value > pauseValue) break;

        // Random interval: 50 - 150, avg: 100ms
        // value will be 100% in 100 * 100ms
        let interval = 50 + Math.random() * 100;
        await new Promise((r) => setTimeout(r, interval));
      }

      // Slow progress, 1% in 2 sec
      while (this.loading) {
        this.value += 1;
        if (this.value > 90) return;

        await new Promise((r) => setTimeout(r, 2000));
      }
    },

    async done() {
      this.loading = false;
      this.value = 100;
      await new Promise((r) => setTimeout(r, 300));
      this.value = 0;
    },

    setLoading(isLoading) {
      if (isLoading) this.increment();
      else this.done();
    },
  },
};
</script>

<style>
</style>