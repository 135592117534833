<template>
  <v-container class="my-12">
    <!-- Quick Action Button -->
    <div class="d-flex">
      <v-btn icon :ripple="false" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-tooltip bottom v-model="isShowLinkCopied">
        <template v-slot:activator="{ attrs }">
          <v-btn icon :ripple="false" @click="copyLink()" v-bind="attrs">
            <v-icon>mdi-link</v-icon>
          </v-btn>
        </template>
        <span>Copied!</span>
      </v-tooltip>
    </div>

    <v-row v-if="member && member.name" class="mt-4">
      <!-- Image -->
      <v-col md="4" sm="5" cols="12">
        <v-img
          class="mx-auto rounded-circle"
          width="240px"
          :aspect-ratio="1"
          :src="member.profile"
        ></v-img>
      </v-col>

      <!-- Info and Bio -->
      <v-col md="8" sm="7" cols="12">
        <h1 class="text-h4 font-weight-bold">{{ member.name }}</h1>
        <h2 class="mt-2 text-subtitle-1 font-weight-bold">
          {{ member.position }}
        </h2>
        <h2 class="mt-2 text-subtitle-1 font-weight-bold">{{ member.role }}</h2>

        <v-divider class="my-4"></v-divider>

        <p class="text-body-2">
          {{ member.description }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { loadTeams } from "@/services/teams";
export default {
  data() {
    return {
      teams: [],
      members: {},
      member: {},

      isShowLinkCopied: false,
    };
  },

  async beforeRouteEnter(to, from, next) {
    let teams = await loadTeams();
    next((vm) => (vm.teams = teams));
  },

  async mounted() {
    if (!this.teams.length) this.teams = await loadTeams();
    this.indexingMembers();
    let key = this.$route.params.member;

    if (!this.members[key]) {
      this.$router.push("/about/team");
      return;
    }

    this.member = this.members[key];
  },

  methods: {
    indexingMembers() {
      if (!this.teams.length) return;
      let members = {};
      this.teams.forEach((team) => {
        team.members.forEach((member) => {
          let key = member.name.replaceAll(" ", "-");
          members[key] = {
            role: team.role,
            ...member,
          };
        });
      });
      this.members = members;
    },

    copyLink(value = window.location.href) {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(value).then(() => {
        this.isShowLinkCopied = true;
        setTimeout(() => (this.isShowLinkCopied = false), 2000);
      });
    },
  },
};
</script>

<style>
</style>