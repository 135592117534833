import moment from 'moment'
import axios from './axios'
import { CONTENT_API_URL, IMAGE_URL } from './config'

export function formatDateRelevent(date) {
  if (moment(date).diff(moment(), 'days') < 1)
    return moment(date).startOf().fromNow()
  return moment(date).format('MMM Do YYYY')
}

export function formatDateShort(date) {
  return moment(date).format('MMM Do YYYY')
}

function parseText(html = "") {
  let div = document.createElement('div');
  div.innerHTML = html.replace(/src="(.+)"/g, '');
  return div.innerText; // html.replace(/<[^>]+>/g, '')
}


// Content-Object should be linked by reference 
// (So, no return for this func.)
function formatContent(content, isFormateBodyImages = false) {
  // Format Create Timestamp
  content.fmtCreatedAt = {
    relevent: formatDateRelevent(content.createdAt),
    short: formatDateShort(content.createdAt)
  }

  // Format Image URL
  content.thumbnail = IMAGE_URL(content.thumbnail)
  content.coverImage = IMAGE_URL(content.coverImage)

  // Format Body
  content.textBody = parseText(content.body)

  // Format Images URL in Body
  if (isFormateBodyImages) {
    content.files.forEach(fileId => {
      content.body = content.body.replaceAll(fileId, IMAGE_URL(fileId))
    })
  }

  if (!content.tags) content.tags = [];
}


export const loadContents = async ({ keywords, tags, maxItems, page } = {}) => {

  if (keywords) keywords = keywords.split(' ')

  let { data, status } = await axios.get(CONTENT_API_URL, { params: { keywords, tags, maxItems, page } })

  // Guard
  if (status != 200 || !data.contents) return {}

  data.contents.forEach((content) => {
    formatContent(content)
  })

  return {
    contents: data.contents,
    count: data.count,
  }
}

export const loadContent = async (_id) => {
  let { data, status } = await axios.get(`${CONTENT_API_URL}/${_id}`)

  // Guard
  if (status != 200 || !data.content) return {}

  let content = data.content;
  formatContent(content, true)

  return content
}

export const postContent = async ({ title, body, thumbnail, coverImage, files, createdAt }) => {
  let formData = new FormData();
  let data = { title, body, thumbnail, coverImage, files, createdAt };
  for (let key in data) {
    if (data[key] === undefined) continue
    if (Array.isArray(data[key])) {
      data[key].forEach(d => formData.append(key, d))
    } else {
      formData.append(key, data[key])
    }
  }
  const result = await axios.post(CONTENT_API_URL, formData)
  return result
}

export const deleteContent = async (_id) => {
  const result = await axios.delete(`${CONTENT_API_URL}/${_id}`)
  return result
}

export const putContent = async ({ _id, title, body, thumbnail, coverImage, files, removedFiles, createdAt }) => {
  if (!_id) throw new Error('_id is empty')
  let formData = new FormData()
  let data = { _id, title, body, thumbnail, coverImage, files, removedFiles, createdAt }

  // Add data to FormData
  for (let key in data) {
    if (data[key] === undefined) continue
    if (Array.isArray(data[key])) {
      data[key].forEach(d => formData.append(key, d))
    } else {
      formData.append(key, data[key])
    }
  }

  const result = await axios.put(`${CONTENT_API_URL}/${_id}`, formData)
  return result
}