// API URL
export const BASE_API_URL = process.env.VUE_APP_BASE_API_URL
export const CONTENT_API_URL = `${BASE_API_URL}/content`
export const TEAM_API_URL = `${BASE_API_URL}/team`
export const AUTHEN_API_URL = `${BASE_API_URL}/auth`
export const USER_API_URL = `${BASE_API_URL}/user`

// Storage Bucket Name
const bucketName = process.env.VUE_APP_IMAGE_BUCKET_NAME

// export const IMAGE_URL = (id) => id && `${BASE_API_URL}/file/${id}`
export const IMAGE_URL = (id) => id && `https://storage.googleapis.com/${bucketName}/${id}`