<template>
  <v-container class="mb-12 doc-page">
    <div class="mt-12 d-flex align-center">
      <div class="text-h4 font-weight-bold">News</div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center" style="width: 280px">
        <v-text-field
          flat
          solo
          dense
          label="Search"
          hide-details
          v-model="keywords"
          @keydown.enter="setSearch()"
        ></v-text-field>

        <v-btn icon class="ml-2" @click="setSearch()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
    </div>

    <v-divider class="mt-8"></v-divider>

    <div v-for="(content, i) in contents" :key="i">
      <NewsRowCard
        class="mt-8"
        :to="`/news/${content._id}`"
        :title="content.title"
        :body="content.textBody"
        :tags="content.tags"
        :timestamp="content.fmtCreatedAt.relevent"
        :thumbnail="content.thumbnail"
      />
    </div>

    <div
      v-if="!contents || !contents.length"
      class="mt-12 grey--text text-center"
    >
      No news article found!
    </div>

    <!-- Page selector -->
    <v-pagination
      v-if="pagesCount > 1"
      class="mt-8"
      v-model="page"
      :length="pagesCount"
      @input="setPage"
      total-visible="4"
    ></v-pagination>
  </v-container>
</template>

<script>
import * as Contents from "@/services/contents";
import NewsRowCard from "@/components/NewsRowCard.vue";

const MAX_ITEMS_PER_PAGE = 10;

export default {
  components: {
    NewsRowCard,
  },

  data() {
    return {
      contents: [],
      count: 0,
      isPageLoaded: false,

      keywords: "",
      page: 1,
    };
  },

  // https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
  async beforeRouteEnter(to, from, next) {
    let { keywords, page } = to.query;
    let data = await Contents.loadContents({
      keywords,
      maxItems: MAX_ITEMS_PER_PAGE,
      page: page,
    });
    next((vm) => vm.setData(data));
  },

  async mounted() {
    this.keywords = this.$route.query.keywords;
    this.page = parseInt(this.$route.query.page) || 1;

    if (!this.isPageLoaded) {
      this.loadContents();
    }
  },

  methods: {
    setData({ contents, count }) {
      this.contents = contents;
      this.count = count;
      this.isPageLoaded = true;
    },

    setSearch() {
      if (this.keywords == this.$route.query.keywords) return;
      this.$router.push({
        query: {
          keywords: this.keywords || undefined,
          page: undefined,
        },
      });
    },

    setPage() {
      if (this.page == this.$route.query.page) return;
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.page == 1 ? undefined : this.page,
        },
      });
    },

    async loadContents() {
      let { keywords, page } = this.$route.query;
      let data = await Contents.loadContents({
        keywords,
        maxItems: MAX_ITEMS_PER_PAGE,
        page: page,
      });
      this.setData(data);
    },
  },

  computed: {
    pagesCount() {
      return Math.ceil(this.count / MAX_ITEMS_PER_PAGE);
    },
  },

  watch: {
    "$route.query": function () {
      this.loadContents();
    },
  },
};
</script>

<style>
</style>