<template>
  <v-card class="pa-3 d-flex shadow-hover transparent" :to="to" :ripple="false">
    <!-- News Card Content -->
    <div class="pr-1 flex-grow-1">
      <div
        class="text-h6 font-weight-bold news-title overflow-hidden line-limit-2"
      >
        <span>{{ title }}</span>
      </div>

      <div class="py-1 d-flex align-center text-caption grey--text">
        <span>{{ timestamp }}</span>

        <v-divider v-if="tags.length" vertical class="mx-2"></v-divider>

        <span v-for="(tag, i) in tags" :key="i">
          <v-icon small v-if="i != 0">mdi-circle-small</v-icon>
          {{ tag }}
        </span>
      </div>

      <div
        class="text-caption wrap-anyware news-body line-limit-2"
      >
        {{ body }}
      </div>

      <router-link class="link text-caption" :to="to">Read more</router-link>
    </div>

    <!-- Thumbnail -->
    <div class="">
      <img :src="thumbnail" class="news-thumbnail" />
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: "",
    },
    title: {
      type: String,
    },
    body: {
      type: String,
    },
    timestamp: {
      type: String,
    },
    thumbnail: {
      type: String,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>

.news-title {
  max-height: 66px;
  overflow: hidden;
}

.news-body {
  max-height: 42px;
  overflow: hidden;
}

.news-thumbnail {
  object-fit: contain;
  width: 148px;
  height: 100%;
  max-height: 156px;

  @media #{map-get($display-breakpoints, 'xs-only' )} {
    width: 100px;
  }
}
</style>