import axios from "axios"
import { isLoggedIn, signout } from "./users"
import store from '@/store'

const Axios = axios.create({
  baseURL: '',
  withCredentials: true,
})

Axios.interceptors.request.use(function (request) {
  store.dispatch('startLoading')
  return request;
})

Axios.interceptors.response.use(function (response) {
  store.dispatch('stopLoading')
  return response
}, function (error) {

  store.dispatch('stopLoading')
  // console.log(`ERR: ${error}`)

  if (error.response && error.response.status == 401) {
    // Singout and Reload Page
    // isLoggedIn().then((isLoggedIn) => {
    //   if (isLoggedIn) {
    //     signout(true)
    //     location.reload(true)
    //   }
    // })
  }

  return Promise.reject(error)
})

export default Axios